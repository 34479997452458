import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  FormLabel,
  FormGroup,
  Slider,
  TextField,
  Typography,
  FormHelperText,
  Modal,
  Snackbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
//import useStore from "../store";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Mail from "@mui/icons-material/Mail";
import Phone from "@mui/icons-material/Phone";
import { Form, Input, DatePicker } from "antd";
import {
  UserOutlined,
  PhoneOutlined,
  MailOutlined,
  CalendarOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
const { TextArea } = Input;
const AddClient = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const path = window.location.pathname;
  const lastSegment = path.split("/").filter(Boolean).pop();
  console.log(lastSegment);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  //const user = useStore((state) => state.user);
  const initialFilters = {
    availability: [],
    sq_ft: [],
    // For Location group - initialized as empty arrays for multi-select
    market: [lastSegment],
    submarket: [],
    city: [],
    state: [],
    zip_code: [],

    // For Units group - initialized as booleans
    studio: false,
    one_bed: false,
    two_bed: false,
    three_bed: false,
    four_plus_bed: false,
    single_family: false,
    townhome: false,
    high_rise: false,
    // For Amenities group - initialized as booleans
    yard: false,
    loft: false,
    garage: false,
    study: false,
    desk: false,
    ev_charger: false,
    fireplace: false,
    no_carpet: false,
    tub_shower: false,
    industrial: false,
    concrete_floors: false,
    furnished: false,
    high_ceilings: false,
    terrace: false,
    roof_terrace: false,
    floor_to_ceiling_windows: false,
    concierge: false,
    washer_dryer_in_unit: false,
    washer_dryer_connections: false,
    washer_dryer_in_building: false,

    year_built: [1990],
    year_renovated: [],
    number_floors: [],
    google_rating: [3.3, 5],
    price_range: [0, 5000],
    admin_fee: [],
    application_fee: [],
    send: [],
    escort: [],
    bonus: [],

    shape: [],
    category: null,
    one_and_a_half_bath: false,
    studio_price: [],
    one_bed_price: [],
    two_bed_price: [],
    three_bed_price: [],
    four_plus_bed_price: [],
    escort_type: "percentage",
    send_type: "percentage",
    min_price: null,
    max_price: null,
  };
  const [modalLoader, setModalLoader] = useState(false);
  const [filters, setFilters] = useState(initialFilters);
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    month: null,
    bedrooms: [],
    budget: 0,
    sq_ft: 0,
    desired_location: "",
    additional_notes: "",
  });

  const [touchedFields, setTouchedFields] = useState({
    firstName: false,
    lastName: false,
    phone: false,
    email: false,
    month: false,
    bedrooms: false,
    desired_location: false,
    additional_notes: false,
    budget: false,
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    console.log(filters);
    console.log(formValues);
    // Handle multiple select
    if (type === "select-multiple") {
      const values = Array.from(
        event.target.selectedOptions,
        (option) => option.value
      );
      setFormValues((prev) => ({
        ...prev,
        [name]: values,
      }));
    } else if (type === "checkbox") {
      setFormValues((prev) => ({
        ...prev,
        [name]: checked,
      }));
    } else if (type === "number") {
      setFormValues((prev) => ({
        ...prev,
        budget: parseInt(value),
      }));
      setFilters((prev) => ({
        ...prev,
        price_range: [0, parseInt(value)],
      }));
    } else {
      setFormValues((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    // Mark field as touched
    setTouchedFields((prev) => ({
      ...prev,
      [name]: true,
    }));
    if (value?.includes("Studio")) {
      setFilters((prev) => ({
        ...prev,
        studio: true,
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        studio: false,
      }));
    }

    if (value?.includes("1 Bed")) {
      setFilters((prev) => ({
        ...prev,
        one_bed: true,
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        one_bed: false,
      }));
    }

    if (value?.includes("2 Bed")) {
      setFilters((prev) => ({
        ...prev,
        two_bed: true,
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        two_bed: false,
      }));
    }

    if (value?.includes("3 Bed")) {
      setFilters((prev) => ({
        ...prev,
        three_bed: true,
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        three_bed: false,
      }));
    }

    if (value?.includes("4 Plus Bed")) {
      setFilters((prev) => ({
        ...prev,
        four_plus_bed: true,
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        four_plus_bed: false,
      }));
    }
  };

  const handleSliderChange = (event, newValue, name) => {
    if (name === "sq_ft") {
      setFormValues((prev) => ({
        ...prev,
        [name]: newValue,
      }));

      setFilters((prev) => ({
        ...prev,
        [name]: [1, newValue],
      }));
    } else if (name === "price") {
      setFormValues((prev) => ({
        ...prev,
        [name]: {
          min: newValue[0],
          max: newValue[1],
        },
      }));

      setFilters((prev) => ({
        ...prev,
        price_range: [newValue[0], parseInt(newValue[1])],
      }));

      setFilters((prev) => ({
        ...prev,
        budget: newValue[1],
      }));

      setFilters((prev) => ({
        ...prev,
        min_price: newValue[0],
        max_price: newValue[1],
      }));
    }
    console.log(filters);
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setModalLoader(true);
      console.log("Form Submitted:", formValues);
      const response = await axios
        .post(`${process.env.REACT_APP_API_URL}/addFormData`, formValues)
        .then(async (response) => {
          if (response.data === "Email Already Exist") {
            setSnackbarMessage("Email Already Exist");
            setSnackbarOpen(true);
          } else {
            setFilters((prev) => ({
              ...prev,
              price_range: [0, parseInt(response?.data?.budget)],
              one_bed: response?.data?.no_of_bedrooms.includes("1 Bed"),
              two_bed: response?.data?.no_of_bedrooms.includes("2 Bed"),
              three_bed: response?.data?.no_of_bedrooms.includes("3 Bed"),
              four_plus_bed:
                response?.data?.no_of_bedrooms.includes("4 Plus Bed"),
              studio: response?.data?.no_of_bedrooms.includes("Studio"),
            }));

            setTimeout(async () => {
              await storeSearch(
                response?.data?.id,
                response?.data?.firstname,
                parseInt(response?.data?.budget),
                response?.data?.no_of_bedrooms.includes("Studio"),
                response?.data?.no_of_bedrooms.includes("1 Bed"),
                response?.data?.no_of_bedrooms.includes("2 Bed"),
                response?.data?.no_of_bedrooms.includes("3 Bed"),
                response?.data?.no_of_bedrooms.includes("4 Plus Bed")
              );
            }, 4000);
          }
        });
    } catch (e) {
      console.log(e);
    } finally {
      setTimeout(() => {
        setSnackbarOpen(false);
        setModalLoader(false);
      }, 3000);
    }
  };

  const storeSearch = async (
    id,
    name,
    maxPrice,
    studio,
    one,
    two,
    three,
    four
  ) => {
    try {
      const { price_range, ...otherFilters } = filters;
      const modifiedFilters = {
        ...otherFilters,
        google_rating: [3.3, 5],
        price_range,
        //min_price: price_range?.[0] || 0,
        max_price: parseInt(maxPrice),
        studio: studio,
        one_bed: one,
        two_bed: two,
        three_bed: three,
        four_plus_bed: four,
      };
      console.log(modifiedFilters);
      const response = await axios
        .post(`${process.env.REACT_APP_API_URL}/search`, {
          filters: modifiedFilters,
          client: id,
          //user: user?.id,
          searchName: name,
        })
        .then((res) => {
          setModalLoader(true);
          setTimeout(() => {}, 3000);
          setSnackbarOpen(true);
          setSnackbarMessage("Form Submitted");
          window.location.href = window.location.href;
        });
    } catch (error) {
      console.error("Error storing search:", error);
    } finally {
      setTimeout(() => {
        setSnackbarOpen(false);
        setModalLoader(false);
      }, 3000);
    }
  };

  const isFormValid =
    formValues.firstName && formValues.firstName.length >= 3 &&
    formValues.lastName && formValues.lastName.length >= 3 &&
    formValues.phone && formValues.phone.length >= 3 &&
    formValues.email && formValues.email.length >= 3 && /^[a-zA-Z0-9._%+-]{3,}@[a-zA-Z0-9.-]{3,}\.[a-zA-Z]{2,}$/.test(
      formValues.email
    ) &&
    formValues.month &&
    formValues.bedrooms.length > 0 &&
    formValues.budget &&
    formValues.additional_notes && formValues.firstName.length >= 3 &&
    formValues.desired_location && formValues.firstName.length >= 3;

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const bedrooms = ["Studio", "1 Bed", "2 Bed", "3 Bed", "4 Plus Bed"];

  const features = [
    {
      value: "Yard",
      key: "yard",
    },
    {
      value: "Garage",
      key: "garage",
    },
    {
      value: "Study",
      key: "study",
    },
    {
      value: "Desk",
      key: "desk",
    },
    {
      value: "EV Charger",
      key: "ev_charger",
    },
    {
      value: "Fireplace",
      key: "fireplace",
    },
    {
      value: "No Carpet",
      key: "no_carpet",
    },
    {
      value: "Tub Shower",
      key: "tub_shower",
    },
    {
      value: "Industrial",
      key: "industrial",
    },
    {
      value: "Concrete Floors",
      key: "concrete_floors",
    },
    {
      value: "Furnished",
      key: "furnished",
    },
    {
      value: "High Ceilings",
      key: "high_ceilings",
    },
    {
      value: "Terrace",
      key: "terrace",
    },
    {
      value: "Roof Terrace",
      key: "roof_terrace",
    },
    {
      value: "Floor To Ceiling Windows",
      key: "floor_to_ceiling_windows",
    },
    {
      value: "Concierge",
      key: "concierge",
    },
    {
      value: "Washer Dryer In Unit",
      key: "washer_dryer_in_unit",
    },
    {
      value: "Washer Dryer Connections",
      key: "washer_dryer_connections",
    },
    {
      value: "Washer Dryer In Building",
      key: "washer_dryer_in_building",
    },
    {
      value: "1.5 Bath",
      key: "one_and_a_half_bath",
    },
    {
      value: "High Rise",
      key: "high_rise",
    },
    {
      value: "Townhome",
      key: "townhome",
    },
    {
      value: "Single Family",
      key: "single_family",
    },
  ];

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return; // Prevents the snackbar from closing when the user clicks outside of it
    }
    setSnackbarOpen(false); // Close the Snackbar
  };
  return (
    <div className="mx-auto md:w-10/12 md:mt-0 mt-4">
      <form onSubmit={handleSubmit}>
        <Grid style={{ marginRight: "2rem", marginLeft: "2rem" }}>
          <Box mt={5} textAlign="center" sx={{ width: "100%" }}>
            <Typography variant="h3" color="primary" mb={3}>
              Apartment Inquiry
            </Typography>
          </Box>

          <Typography
            variant="h5"
            color="primary"
            style={{ borderBottom: "1px solid rgba(0,0,0,.125)" }}
          >
            Client Information
          </Typography>

          {/* First Name */}
          <Typography
            variant="h6"
            style={{
              color: "rgba(0,0,0,.700)",
              marginBottom: "2px",
              marginTop: "5px",
            }}
          >
            First Name <em style={{ color: "red" }}>*</em>
          </Typography>
          <Form.Item
            name="firstName"
            rules={[
              { required: true, message: "* First Name is required." },
              { min: 3, message: "* Minimum 3 characters required." },
            ]}
            validateStatus={
              touchedFields.firstName && !formValues.firstName
                ? "error"
                : formValues.firstName.length < 3 && touchedFields.firstName
                ? "error"
                : ""
            }
            help={
              touchedFields.firstName && !formValues.firstName
                ? "* First Name is required."
                : formValues.firstName.length < 3 && touchedFields.firstName
                ? "* Minimum 3 characters required."
                : ""
            }
            style={{ width: "100%", marginBottom: "0px" }}
          >
            <Input
              prefix={
                <UserOutlined
                  style={{
                    fontSize: isMobile ? "1rem" : "1rem",
                    borderRadius: "30%",
                    backgroundColor: "transparent",
                    padding: isMobile ? "4px" : "8px",
                    background: "#4096f",
                  }}
                />
              }
              value={formValues.firstName}
              name="firstName"
              onChange={handleChange}
              autoComplete="off"
              style={{
                fontSize: isMobile ? "1rem" : "1rem",
                padding: isMobile ? "12px" : "10px",
                borderRadius: "20px",
              }}
            />
          </Form.Item>

          {/* Last Name */}
          <Typography
            variant="h6"
            style={{
              color: "rgba(0,0,0,.700)",
              marginBottom: "2px",
              marginTop: "5px",
            }}
          >
            Last Name <em style={{ color: "red" }}>*</em>
          </Typography>
          <Form.Item
            name="lastName"
            rules={[
              { required: true, message: "* Last Name required." },
              { min: 3, message: "* Minimum 3 characters required." },
            ]}
            validateStatus={
              touchedFields.lastName && !formValues.lastName
                ? "error"
                : formValues.lastName.length < 3 && touchedFields.lastName
                ? "error"
                : ""
            }
            help={
              touchedFields.lastName && !formValues.lastName
                ? "* Last Name is required."
                : formValues.lastName.length < 3 && touchedFields.lastName
                ? "* Minimum 3 characters required."
                : ""
            }
            style={{ width: "100%", marginBottom: "0px" }}
          >
            <Input
              prefix={
                <UserOutlined
                  style={{
                    fontSize: isMobile ? "1rem" : "1rem",
                    borderRadius: "30%",
                    backgroundColor: "transparent",
                    padding: isMobile ? "4px" : "8px",
                    color: "#4096f",
                  }}
                />
              }
              value={formValues.lastName}
              name="lastName"
              onChange={handleChange}
              autoComplete="off"
              style={{
                fontSize: isMobile ? "1rem" : "1rem",
                padding: isMobile ? "12px" : "10px",
                borderRadius: "20px",
              }}
            />
          </Form.Item>

          {/* Phone */}
          <Typography
            variant="h6"
            style={{
              color: "rgba(0,0,0,.700)",
              marginBottom: "2px",
              marginTop: "5px",
            }}
          >
            Phone <em style={{ color: "red" }}>*</em>
          </Typography>
          <Form.Item
            name="phone"
            rules={[
              { required: true, message: "* Phone number required." },
              { min: 3, message: "* Minimum 3 characters required." },
            ]}
            validateStatus={
              touchedFields.phone && !formValues.phone
                ? "error"
                : formValues.phone.length < 3 && touchedFields.phone
                ? "error"
                : ""
            }
            help={
              touchedFields.phone && !formValues.phone
                ? "* Phone number required."
                : formValues.phone.length < 3 && touchedFields.phone
                ? "* Minimum 3 characters required."
                : ""
            }
            style={{ width: "100%", marginBottom: "0px" }}
          >
            <Input
              prefix={
                <PhoneOutlined
                  style={{
                    fontSize: isMobile ? "1rem" : "1rem",
                    borderRadius: "30%",
                    backgroundColor: "transparent",
                    padding: isMobile ? "4px" : "8px",
                    transform: "rotate(90deg)"
                  }}
                />
              }
              value={formValues.phone}
              name="phone"
              onChange={handleChange}
              autoComplete="off"
              style={{
                fontSize: isMobile ? "1rem" : "1rem",
                padding: isMobile ? "12px" : "10px",
                borderRadius: "20px",
              }}
            />
          </Form.Item>

          {/* Email */}
          <Typography
            variant="h6"
            style={{
              color: "rgba(0,0,0,.700)",
              marginBottom: "2px",
              marginTop: "5px",
            }}
          >
            Email <em style={{ color: "red" }}>*</em>
          </Typography>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "* Email is required." },
              {
                pattern:
                  /^[a-zA-Z0-9._%+-]{3,}@[a-zA-Z0-9.-]{3,}\.[a-zA-Z]{2,}$/,
                message:
                  "* Enter a valid email address",
              },
            ]}
            validateStatus={
              touchedFields.email &&
              (!formValues.email
                ? "error"
                : !/^[a-zA-Z0-9._%+-]{3,}@[a-zA-Z0-9.-]{3,}\.[a-zA-Z]{2,}$/.test(
                    formValues.email
                  )
                ? "error"
                : "")
            }
            help={
              touchedFields.email &&
              (!formValues.email
                ? "* Email is required."
                : !/^[a-zA-Z0-9._%+-]{3,}@[a-zA-Z0-9.-]{3,}\.[a-zA-Z]{2,}$/.test(
                    formValues.email
                  )
                ? "* Enter a valid email address with at least 3 characters before and after '@', and after '.'."
                : "")
            }
            style={{ width: "100%", marginBottom: "0px" }}
          >
            <Input
              prefix={
                <MailOutlined
                  style={{
                    fontSize: isMobile ? "1rem" : "1rem",
                    borderRadius: "30%",
                    backgroundColor: "transparent",
                    padding: isMobile ? "4px" : "8px",
                  }}
                />
              }
              value={formValues.email}
              name="email"
              onChange={handleChange}
              autoComplete="off"
              style={{
                fontSize: isMobile ? "1rem" : "1rem",
                padding: isMobile ? "12px" : "10px",
                borderRadius: "20px",
              }}
            />
          </Form.Item>

          <Typography
            variant="h6"
            style={{
              color: "rgba(0,0,0,.700)",
              marginBottom: "2px",
              marginTop: "5px",
            }}
          >
            Desired Location <em style={{ color: "red" }}>*</em>
          </Typography>
          <Typography variant="h8" style={{ color: "rgba(0,0,0,.500)" }}>
            {lastSegment === "DFW"
              ? `i.e. Addison, Uptown, Frisco, Plano, etc. Within 30 minutes of your job. Not sure on location? I can help! Just tell me what you’re looking for in your new neighborhood!`
              : `i.e. South Lamar, South Austin, Mueller, etc. Within 30 minutes of your job. Not sure on location? I can help! Just tell me what you’re looking for in your new neighborhood!`}
          </Typography>
          <Form.Item
            style={{ marginBottom: 0, marginTop: "10px" }}
            validateStatus={
              touchedFields.desired_location && !formValues.desired_location
                ? "error"
                : touchedFields.desired_location &&
                  formValues.desired_location.length < 3
                ? "error"
                : ""
            }
            help={
              touchedFields.desired_location && !formValues.desired_location
                ? "* Desired Location required."
                : touchedFields.desired_location &&
                  formValues.desired_location.length < 3
                ? "Minimum 3 characters required."
                : ""
            }
          >
            <TextArea
              rows={4}
              value={formValues.desired_location}
              name="desired_location"
              onChange={handleChange}
              autoComplete="off"
              style={{
                width: "100%",
                fontSize: isMobile ? "1rem" : "1rem",
                padding: isMobile ? "12px" : "10px",
                borderRadius: "20px",
              }}
            />
          </Form.Item>
          {/* Number of Bedrooms */}
          <FormControl fullWidth required>
            <Typography
              variant="h6"
              style={{
                color: "rgba(0,0,0,.700)",
                marginBottom: "2px",
                marginTop: "5px",
              }}
            >
              Unit Size <em style={{ color: "red" }}>*</em>
            </Typography>
            <FormGroup>
              {bedrooms.map((bedroom, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: isMobile ? "1rem" : "1rem", // Checkbox size
                        },
                      }}
                      checked={formValues.bedrooms.includes(bedroom)} // Check if the bedroom is selected
                      onChange={(e) => {
                        // Handle adding/removing the bedroom from the array
                        const newBedrooms = e.target.checked
                          ? [...formValues.bedrooms, bedroom] // Add to selection
                          : formValues.bedrooms.filter((b) => b !== bedroom); // Remove from selection
                        handleChange({
                          target: {
                            name: "bedrooms",
                            value: newBedrooms,
                          },
                        });
                      }}
                    />
                  }
                  label={bedroom}
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: isMobile ? "1rem" : "1rem", // Label text size
                    },
                  }}
                />
              ))}
            </FormGroup>
            {touchedFields.bedrooms && formValues.bedrooms.length === 0 && (
              <Typography
                variant="caption"
                color="error"
                sx={{ fontSize: isMobile ? "14px" : "14px" }}
              >
                * Unit size required.
              </Typography>
            )}
          </FormControl>

          {/* Budget */}
          <Typography
            variant="h6"
            style={{
              color: "rgba(0,0,0,.700)",
              marginBottom: "2px",
              marginTop: "5px",
            }}
          >
            Max Budget <em style={{ color: "red" }}>*</em>
          </Typography>
          <Form.Item
            name="budget"
            rules={[{ required: true, message: "* Budget required." }]}
            validateStatus={
              touchedFields.budget && !formValues.budget ? "error" : ""
            }
            help={
              touchedFields.budget && !formValues.budget
                ? "* Budget required."
                : ""
            }
            style={{ width: "100%", marginBottom: "0px" }}
          >
            <Input
              prefix={
                <DollarOutlined
                  style={{
                    fontSize: isMobile ? "1rem" : "1rem",
                    borderRadius: "30%",
                    backgroundColor: "transparent",
                    padding: isMobile ? "4px" : "8px",
                  }}
                />
              }
              min={0}
              type="number"
              value={formValues.budget}
              name="budget"
              onChange={handleChange}
              autoComplete="off"
              style={{
                fontSize: isMobile ? "1rem" : "1rem",
                padding: isMobile ? "12px" : "10px",
                borderRadius: "20px",
              }}
            />
          </Form.Item>

          {/* Move in Month */}
          <Typography
            variant="h6"
            style={{
              color: "rgba(0,0,0,.700)",
              marginBottom: "2px",
              marginTop: "5px",
            }}
          >
            Move in Date <em style={{ color: "red" }}>*</em>
          </Typography>

          <Form.Item
            validateStatus={
              touchedFields.month && !formValues.month ? "error" : ""
            }
            help={
              touchedFields.month && !formValues.month
                ? "* Move in Date required."
                : ""
            }
          >
            <Input
              type="date"
              prefix={
                <CalendarOutlined
                  style={{
                    fontSize: isMobile ? "1rem" : "1rem",
                    borderRadius: "30%",
                    backgroundColor: "transparent",
                    padding: isMobile ? "4px" : "8px",
                  }}
                />
              }
              value={formValues.month}
              name="month"
              onChange={handleChange}
              autoComplete="off"
              style={{
                fontSize: isMobile ? "1rem" : "1rem",
                padding: isMobile ? "12px" : "10px",
                borderRadius: "20px",
              }}
            />
          </Form.Item>

          <Typography
            variant="h6"
            style={{
              color: "rgba(0,0,0,.700)",
              marginBottom: "2px",
              marginTop: "5px",
            }}
          >
            What else would you like to tell me? The more I know, the better!{" "}
            <em style={{ color: "red" }}>*</em>
          </Typography>
          <Typography variant="h8" style={{ color: "rgba(0,0,0,.500)" }}>
            Are there any specific features you’d like to have in your new home?
            Yard, etc? Any credit or background concerns? Any other details
            you’d like me to know while looking for your new home?
          </Typography>
          <Form.Item
            style={{ marginBottom: 0, marginTop: "10px" }}
            validateStatus={
              touchedFields.additional_notes && !formValues.additional_notes
                ? "error"
                : touchedFields.additional_notes &&
                  formValues.additional_notes.length < 3
                ? "error"
                : ""
            }
            help={
              touchedFields.additional_notes && !formValues.additional_notes
                ? "* The Field is required."
                : touchedFields.additional_notes &&
                  formValues.additional_notes.length < 3
                ? "* Minimum 3 characters required."
                : ""
            }
          >
            <TextArea
              rows={4}
              value={formValues.additional_notes}
              name="additional_notes"
              onChange={handleChange}
              autoComplete="off"
              style={{
                width: "100%",
                fontSize: isMobile ? "1rem" : "1rem",
                padding: isMobile ? "12px" : "10px",
                borderRadius: "20px",
              }}
            />
          </Form.Item>

          {/* Preferences */}
          {/* <Grid container spacing={4} sx={{ mb: 4 }}>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                color="primary"
                style={{ borderBottom: "1px solid rgba(0,0,0,.125)" }}
              >
                Preferences
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography display="inline" style={{ fontSize: "20px" }}>
                Square Feet
              </Typography>
              <Box mt={2} width="100%">
                <Slider
                  value={formValues.sq_ft}
                  onChange={(event, newValue) =>
                    handleSliderChange(event, newValue, "sq_ft")
                  }
                  aria-labelledby="square-feet-slider"
                  valueLabelDisplay="on"
                  min={0}
                  max={5000}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography display="inline" style={{ fontSize: "20px" }}>
                Price
              </Typography>
              <Box mt={2} width="100%">
                <Slider
                  value={[formValues.price.min, formValues.price.max]}
                  onChange={(event, newValue) =>
                    handleSliderChange(event, newValue, "price")
                  }
                  valueLabelDisplay="on"
                  aria-label="Price"
                  min={0}
                  max={5000}
                />
              </Box>
            </Grid>
          </Grid> */}

          {/* Amenities */}
          {/* <Grid container spacing={4} sx={{ mb: 4 }}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              color="primary"
              style={{ borderBottom: "1px solid rgba(0,0,0,.125)" }}
            >
              Amenities
            </Typography>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              {features.map((feature) => (
                <Grid item xs={12} md={12} lg={12} key={feature}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="w-auto"
                        name={feature.key}
                        onChange={(e) => {
                          setFilters((prev) => ({
                            ...prev,
                            [e.target.name]: e.target.checked,
                          }));
                        }}
                      />
                    }
                    label={feature.value}
                    name={feature.key}
                    value={feature.key}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid> */}

          {/* Submit Button */}
          <Grid
            container
            spacing={4}
            sx={{
              mb: 4,
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{ height: "50px", width: "100px", borderRadius: "20px" }}
              disabled={!isFormValid}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
        <Modal open={modalLoader}>
          <Box
            sx={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress disableShrink />
              </div>
            </div>
          </Box>
        </Modal>
        <Snackbar
          open={snackbarOpen}
          //style={{ right: 0, top: "65px" }}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
        />
      </form>
    </div>
  );
};

export default AddClient;
